.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 2rem;
}

.main > div {
  flex-basis: 33%;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }
}
