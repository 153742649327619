.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1.2rem;
  cursor: pointer;
}

.card {
  width: 350px;
  min-height: 180px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: .75rem;
}

.badge {
  position: absolute;
  top: .3rem;
  right: .3rem;
  padding: .1rem .3rem;
  font-weight: bold;
  border-radius: 4px;
  font-size: .7rem;
}

.badge[data-type="warning"] {
  color: white;
  background-color: var(--color-primary-orange);
}

.cover {
  margin-bottom: .75rem;
  max-height: 3rem;
  max-width: 100%;
  text-align: center;
}

.description {
  width: 100%;
  padding: 0 .5rem;
}
