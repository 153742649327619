.main {
  position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  z-index: 99;
}

.paper {
  width: 240px;
  background: var(--color-dark);
  color: white;
  padding-top: 15px;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem;
  justify-content: flex-start;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.link:hover {
  color: var(--color-primary-green);
}

.profile {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 1rem;
}